<template>
  <div class="step_box">
    <h1>最终合作协议</h1>
    <template v-if="file">
      <info-item
        :label="showNext ? '确认合作协议' : '下载合作协议'"
        label-width="120px">
        <el-button
          type="primary"
          plain
          size="small"
          @click="onDownload">
          {{ file.originName }}
        </el-button>
      </info-item>
      <info-item
        v-if="showNext"
        label-width="120px">
        <el-button
          type="primary"
          size="small"
          :loading="btnLoading"
          @click="confirmContract">
          确认协议
        </el-button>
      </info-item>
    </template>
    <template v-else>
      合同生成中，请等待！
    </template>
  </div>
</template>

<script>

import * as api from '@/api'
export default {
  data () {
    return {
      btnLoading: false
    }
  },
  props: {
    showNext: {
      type: Boolean,
      default: false
    },
    // 合同信息对象
    contractResult: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    // 合同文件对象
    file () {
      return this.contractResult.finalContract
    }
  },
  methods: {
    // 下载合同
    onDownload () {
      window.open(this.file.url)
    },
    // 确认协议
    confirmContract () {
      this.btnLoading = true
      api.confirmContract(this.contractResult.id).then(res => {
        if (res.data.code === 0) {
          console.log('确认了合同', res.data.data)
          this.$message.success('确认合作协议成功！')
          this.$emit('reload')
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.btnLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .step_box {
    width: 470px;
    & > h1 {
      font-size: 14px;
      line-height: 40px;
      color: #303133;
      margin-bottom: 10px;
    }
  }
</style>
