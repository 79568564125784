<!-- 价格磋商 -->
<template>
  <div class="info_module">
    <div class="module_header">
      <h1>价格磋商</h1>
      <el-button
        v-if="showAddBtn"
        size="small"
        @click="addPriceDialogVisible = true">
        新增报价
      </el-button>
    </div>
    <div class="module_main">
      <div
        class="price_row"
        v-for="item in priceListShowed"
        :key="item.id">
        <div class="row_left">
          <div class="left_title">
            <p class="company_name">
              <span>{{item.organizationName}}</span>
              <span>{{item.legalPerson}}</span>
            </p>
            <p class="price_text">
              <span class="tw-text-secondary-text">
                修改报价总额
              </span>
              <span class="tw-text-20px tw-text-primary tw-font-bold">
                {{item.price}}元
              </span>
            </p>
          </div>
          <div class="left_log">
            <div class="left_log_label">报价明细</div>
            <div class="left_log_value">
              <span v-for="(priceItem, index) in item.quotationDetails" :key="priceItem.id">
                {{priceItem.name}}:{{priceItem.price}}元{{index === item.quotationDetails.length - 1 ? '' : ','}}
              </span>
            </div>
          </div>
        </div>
        <div
          v-if="showBtns"
          class="row_right">
          <p @click="onCustomizePrice(item)">
            自定义
          </p>
          <p
            v-if="showConfirmBtn"
            @click="onConfirmPrice(item)">
            确认报价
          </p>
        </div>
      </div>
    </div>

    <!-- 自定义报价明细 -->
    <add-price
      :visible.sync="addPriceDialogVisible"
      :require-id="detail.id"
      :quotation-id="quotationId"
      @add-price-success="$emit('reload')">
    </add-price>
  </div>
</template>

<script>
import globalVar from '@/configs/globalVar'
import AddPrice from './add-price.vue'
import * as api from '@/api'

export default {
  components: {
    AddPrice
  },
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      // 新增报价
      addPriceDialogVisible: false,
      quotationId: ''
    }
  },
  computed: {
    priceList () {
      return this.detail.quotationList
    },
    logicStatus () {
      return this.detail.logicStatus
    },
    isPublisher () {
      return this.detail.publisher === this.userInfo.id
    },
    showAddBtn () {
      return this.priceListShowed.length <= 0 && !this.isPublisher
    },
    /**
     * 如果是需求发布者视角
     *  + 则返回所有的磋商列表
     *  + 否则只返回当前用户的价格磋商列表
     */
    priceListShowed () {
      return this.isPublisher ? this.priceList : this.priceList.filter(item => {
        return item.userId === this.userInfo.id
      })
    },
    showBtns () {
      return [
        globalVar.REQUIRE_STATUS_PUBLISHED,
        globalVar.REQUIRE_STATUS_RECEIVED,
        globalVar.REQUIRE_STATUS_CONFIRMED
      ].indexOf(this.logicStatus) !== -1
    },
    showConfirmBtn () {
      return this.isPublisher
    }
  },
  methods: {
    onCustomizePrice (item) {
      this.quotationId = item.id
      this.addPriceDialogVisible = true
    },
    onConfirmPrice (item) {
      this.$msgbox({
        title: '确认',
        message: '请确认你的操作',
        showCancelButton: true,
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            api.confirmRequirePrice(item.id).then(res => {
              if (res.data.code === 0) {
                this.$message.success('已确认报价')
                this.$emit('reload')
              } else {
                this.$message.error(res.data.message)
              }
            }).catch(err => {
              console.log(err)
            }).finally(() => {
              instance.confirmButtonLoading = false
              done()
            })
          } else {
            done()
          }
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
    .module_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      & > h1 {
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
        color: #303133;
      }
    }
  .price_row {
    display: flex;
    padding: 10px 0 20px 0;
    &:not(:only-child) {
      border-bottom: 1px solid #DCDFE6;
    }
    .row_left {
      flex: 1;
      .left_title {
        display: flex;
        align-items: center;
        height: 32px;
        font-size: 14px;
        color: #303133;
        line-height: 32px;
        margin-bottom: 6px;
        & > p {
          & > span {
            &+span {
              margin-left: 10px;
            }
          }
          &+p {
            margin-left: 10px;
          }
        }
      }
      .left_log {
        display: flex;
        font-size: 14px;
        line-height: 32px;
        .left_log_label {
          color: #909399;
          flex: 0 0 120px;
        }
        .left_log_value {
          color: #303133;
        }
      }
    }
    .row_right {
      flex: 0 0 60px;
      margin-left: 50px;
      & > p {
        color: #3473E6;
        line-height: 20px;
        text-align: right;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
        &+p {
          margin-top: 10px;
        }
      }
    }
    &+.price_row {
      margin-top: 10px;
    }
  }
</style>
