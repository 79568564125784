<!-- 选择服务用户/服务集成商 -->
<template>
  <el-dialog
    custom-class="my-el-dialog"
    title="选择服务用户"
    :visible.sync="$_visible"
    width="520px"
    :close-on-click-modal="false"
    append-to-body
    @open="onDialogOpen"
    @close="onDialogClose">
    <el-form
      class="tw-px-20px"
      ref="form"
      :model="form"
      :rules="rules"
      label-width="120px"
      label-position="left"
      size="small">
      <el-form-item
        prop="userId"
        label="服务用户">
        <div class="tw-flex tw-justify-between">
          <el-select
            style="width:100%;"
            v-model="form.userId"
            filterable>
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.organizationName + item.username"
              :value="item.id">
            </el-option>
          </el-select>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="footer">
      <el-button
        size="small"
        @click="onDialogClose">
        取消
      </el-button>
      <el-button
        type="primary"
        size="small"
        :loading="saveBtnLoading"
        @click="saveForm">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as api from '@/api'
import globalVar from '@/configs/globalVar'

export default {
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    },
    requireId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      saveBtnLoading: false,
      // 添加表单项时
      form: {
        userId: ''
      },
      rules: {
        userId: [
          { required: true, message: '请选择服务用户或服务集成商用户', trigger: 'blur' }
        ]
      },
      // 级联选项
      options: []
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    }
  },
  methods: {
    // 进行保存
    saveForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.btnLoading = true
          api.allocateForSubRequire(this.requireId, {
            userId: this.form.userId
          }).then(res => {
            if (res.data.code === 0) {
              this.$message.success('操作成功')
              this.$emit('allocate-success')
              this.$_visible = false
            } else {
              this.$message.error(res.data.message)
            }
          }).catch(err => {
            console.log(err)
          }).finally(() => {
            this.btnLoading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 关闭新建里程碑的dialog
    onDialogClose () {
      this.$_visible = false
      this.$refs.form.resetFields()
    },
    onDialogOpen () {
      api.getUserListByRole({
        role: globalVar.USER_ROLE_1
      }).then(res => {
        if (res.data.code === 0) {
          this.options = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .item_icon_box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #DCDFE6;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 42px;
    &:hover {
      background: #fff;
      border-color: #3473E6;
      color: #3473E6;
    }
  }
</style>
