<!-- 合同信息 -->
<template>
  <div class="info_module">
    <h1>合同信息</h1>
    <div class="module_main">
      <div class="contract_wrapper">
        <div class="left_steps">
          <el-steps
            direction="vertical"
            finish-status="success"
            :active="curStep">
            <el-step :title="stepOneTitle"></el-step>
            <el-step :title="stepTwoTitle"></el-step>
            <el-step title="确认合作协议"></el-step>
          </el-steps>
        </div>
        <div class="right_main">
          <!-- 填写合同信息 -->
          <step-one
            v-if="curStep === 0 && isPartyB"
            @reload="$emit('reload')"
            :detail="detail">
          </step-one>
          <!-- 签名 -->
          <step-two
            v-else-if="curStep === 1"
            :title="stepTwoTitle"
            :detail="detail"
            @reload="$emit('reload')">
          </step-two>
          <!-- 确认协议 -->
          <step-three
            v-else-if="curStep === 2"
            :contractResult="detail.contractResult"
            :showNext="true"
            @reload="$emit('reload')">
          </step-three>
          <!-- 下载协议 -->
          <step-three
            :contractResult="detail.contractResult"
            v-else-if="curStep === 3">
          </step-three>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import stepOne from './step-one.vue'
import stepTwo from './step-two.vue'
import stepThree from './step-three.vue'
export default {
  components: { stepOne, stepTwo, stepThree },
  data () {
    return {
      // 当前步骤
      // curStep: 0
    }
  },
  props: {
    // 需求详情
    detail: {
      type: Object,
      default: () => {}
    },
    // 是否是需求发布者
    isPublisher: {
      type: Boolean,
      default: false
    },
    // 是否是乙方
    isPartyB: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    // 第一步标题
    stepOneTitle () {
      return this.isPublisher ? '等待乙方填写合同信息' : this.isPartyB ? '填写合同信息' : ''
    },
    // 第二步标题
    stepTwoTitle () {
      return this.isPublisher ? '上传甲方签名' : this.isPartyB ? '上传乙方签名' : ''
    },
    // 合同信息
    contractResult () {
      return this.detail.contractResult || null
    },
    curStep () {
      let curStep = 0
      if (this.contractResult) {
        const flag = this.contractResult.flag
        if (this.contractResult.contractParams.length > 0) {
          curStep = 1
        }
        if (this.isPublisher) {
          // 如果有了签名 则跳到确认合同这一步
          if (this.contractResult.org1Autograph) {
            curStep = 2
          }
          // 如果已经确认了，则去下载合同
          if (flag & 1) {
            console.log(flag & 1)
            curStep = 3
          }
        }
        if (this.isPartyB) {
          // 如果有了签名 则跳到确认合同这一步
          if (this.contractResult.org2Autograph) {
            curStep = 2
          }
          // 如果已经确认了，则去下载合同
          if (flag & 2) {
            console.log(flag & 2)
            curStep = 3
          }
        }
      }
      // 如果有了合同信息
      return curStep
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .contract_wrapper {
    display: flex;
    padding: 10px 0 30px 0;
    .left_steps {
      flex: 0 0 230px;
      height: 150px;
    }
    .right_main {
      flex: 1;
    }
  }
</style>
