<template>
  <div class="step_box">
    <h1>{{ title }}</h1>
    <info-item
      label="扫码上传"
      :value="imgSrc"
      image-width="140px"
      image-height="140px"
      label-width="120px"
      value-type="image">
    </info-item>
    <info-item
      label-width="120px">
      <el-button
        type="primary"
        plain
        size="small"
        @click="$emit('reload')">
        下一步
      </el-button>
    </info-item>
  </div>
</template>

<script>
const QRCode = require('qrcode')

export default {
  data () {
    return {
      imgSrc: ''
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    // 需求id
    requireId () {
      return this.$route.params.id || ''
    },
    // 当前用户id
    curUserId () {
      return this.userInfo.id
    }
  },
  created () {
    this.renderQrcode()
  },
  methods: {
    // 生成img
    renderQrcode  () {
      var opts = {
        type: 'image/jpeg'
      }
      const url = `${window.location.origin}/signature/${this.requireId}/${this.curUserId}`
      console.log('签名地址', url)
      QRCode.toDataURL(url, opts, (err, url) => {
        if (err) throw err
        this.imgSrc = url
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .step_box {
    width: 470px;
    & > h1 {
      font-size: 14px;
      line-height: 40px;
      color: #303133;
      margin-bottom: 10px;
    }
  }
</style>
