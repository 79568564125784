<!-- 自定义报价明细 添加报价 -->
<template>
  <el-dialog
    custom-class="my-el-dialog"
    title="提交报价明细"
    :visible.sync="$_visible"
    width="520px"
    :close-on-click-modal="false"
    append-to-body
    @close="onDialogClose">
    <el-form
      class="tw-px-20px"
      ref="form"
      :model="form"
      :rules="rules"
      label-position="top"
      size="small">
      <el-row
        type="flex"
        justify="space-between"
        v-for="(pItem, pIndex) in form.priceList"
        :key="pItem.id">
        <div>
          <el-form-item
            label="明细类型"
            :prop="`priceList[${pIndex}].name`"
            :rules="[
              { required: true, message: '请输入明细类型', trigger: 'blur' },
            ]">
            <el-input maxlength="50" show-word-limit
              style="width:194px;"
              placeholder="请输入明细类型"
              v-model="pItem.name">
            </el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item
            label="价格"
            :prop="`priceList[${pIndex}].price`"
            :rules="[
              { required: true, message: '请输入价格（元）', trigger: 'blur' },
              { pattern: /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,})?$/, message: '请输入正确价格', trigger: 'blur' },
            ]">
            <el-input maxlength="50" show-word-limit
              style="width:194px;"
              placeholder="请输入价格（元）"
              v-model="pItem.price">
            </el-input>
          </el-form-item>
        </div>
        <div
          class="item_icon_box"
          @click="deletePriceItem(pItem.id)">
          <i class="iconfont iconshanchu"></i>
        </div>
      </el-row>
      <el-button
        size="small"
        @click="addPriceItem">
        <i class="el-icon-plus"></i> 新增选项
      </el-button>
    </el-form>
    <div slot="footer" class="footer">
      <el-button
        size="small"
        @click="closeDialog">
        取消
      </el-button>
      <el-button
        type="primary"
        size="small"
        :loading="saveBtnLoading"
        @click="saveForm">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import * as api from '@/api'
import { getUid } from '@/utils/utils'

export default {
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    },
    // 提交报价
    requireId: {
      type: String,
      default: ''
    },
    quotationId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      saveBtnLoading: false,
      // 添加表单项时
      form: {
        // priceList: []
        priceList: [
          // {
          //   id: '33isly4zg7cnhfaff37yy6av',
          //   name: '服务费',
          //   price: '500'
          // },
          // {
          //   id: '7palbgbk0j43jw5chz0wmq90',
          //   name: '材料费',
          //   price: '500'
          // },
          // {
          //   id: '51xlz9yf7fiqqgpis2vpmyv9',
          //   name: '人工费',
          //   price: '100'
          // }
        ]
      },
      rules: {

      }
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    }
  },
  created () {
    // 先添加一项
    this.addPriceItem()
  },
  methods: {
    // 获取套餐中的优惠项空对象
    getPriceItemEmptyObj () {
      return {
        id: getUid(), // 价格项id
        name: '', // 明细类型
        price: '' // 价格
      }
    },

    // 新增一个价格项
    addPriceItem () {
      const priceItem = this.getPriceItemEmptyObj()
      this.form.priceList.push(priceItem)
    },
    // 删除一个价格项
    deletePriceItem (id) {
      const index = this.form.priceList.findIndex(item => {
        return item.id === id
      })
      if (index !== -1) {
        this.form.priceList.splice(index, 1)
      }
    },

    // 进行保存
    saveForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitRequirePrice()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 提交报价
    submitRequirePrice () {
      this.saveBtnLoading = true
      api.submitRequirePrice(this.requireId, {
        quotation: this.form.priceList,
        quotationId: this.quotationId
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('提交报价成功')
          // 关闭弹窗
          this.closeDialog()
          this.$emit('add-price-success')
        } else {
          this.$message.error(res.data.message)
        }
        this.saveBtnLoading = false
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.saveBtnLoading = false
      })
    },
    // 关闭新建里程碑的dialog
    closeDialog () {
      this.$_visible = false
    },
    // dialog关闭事件
    onDialogClose () {
      this.$refs.form.resetFields()
      this.form.priceList = []
      this.addPriceItem()
    }
  }
}
</script>
<style lang="scss" scoped>
  .item_icon_box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #DCDFE6;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 42px;
    &:hover {
      background: #fff;
      border-color: #3473E6;
      color: #3473E6;
    }
  }
</style>
