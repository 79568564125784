<!--
  需求详情
-->
<template>
  <div class="detail">
    <detail-header title="需求详情" />
    <div class="detail_main page_width" v-loading="loading">
      <div class="main_content">
        <!-- 基本信息 -->
        <div class="info_module base_info">
          <h1>基本信息</h1>
          <div class="module_main">
            <info label-width="140px">
              <info-item
                label="需求名称">
                {{ detail.title }}
              </info-item>
              <info-item
                label="需求类别">
                {{ detail.serviceTypeName }}
              </info-item>
              <info-item
                label="行业领域">
                {{ detail.industryName ? detail.industryName.join(',') : '' }}
              </info-item>
              <info-item
                label="技术领域">
                {{ detail.technicalName ? detail.technicalName.join(',') : '' }}
              </info-item>
              <info-item
                label="时限要求">
                {{ detail.startAt }} - {{ detail.endAt }}
              </info-item>
              <info-item
                label="需求描述">
                {{ detail.description }}
              </info-item>
              <info-item
                label="关键字">
                {{ detail.keywords }}
              </info-item>
              <info-item
                label="预算金额">
                {{ detail.budget }}元
              </info-item>
              <info-item
                label="需求所属企业名称">
                {{ detail.enterpriseName }}
              </info-item>
              <info-item
                label="统一社会信用代码">
                {{ detail.socialCreditCode }}
              </info-item>
              <info-item
                label="企业所在区域">
                {{ detail.province }}
              </info-item>
              <info-item
                label="联系人">
                {{ detail.contacts }}
              </info-item>
              <info-item
                label="联系手机">
                {{ detail.contactMobile }}
              </info-item>
              <info-item
                label="联系邮箱">
                {{ detail.contactEmail }}
              </info-item>
              <!-- 需要先判断时候是简单需求还是复杂需求 -->
              <info-item
                v-if="detail.reportFlag"
                label="客服备注">
                <el-button
                  size="small"
                  type="primary"
                  @click="questionInfoDialogVisible = true">
                  查看备注
                </el-button>
              </info-item>
            </info>
          </div>
        </div>

        <template v-if="showModules">
          <!--
            诊断报告
              + 被分配
              + 状态为已接受 | 非待处理
              + 被分配人类型为服务集成商
              + 需求为复杂需求
          -->
          <diagnostic-report
            v-if="showDiagnosticReport"
            :detail="detail"
            @reload="getRequireFormDetail">
          </diagnostic-report>

          <!--
            价格磋商
              + 被接受
                + 已发布
              + 被分配
                + 服务用户
                  + 已接受
                + 集成商用户
                  + 已确认
          -->
          <discuss-price
            v-if="showDiscussPrice"
            :detail="detail"
            @reload="getRequireFormDetail">
          </discuss-price>

          <!--
            选择合作方式
              + 状态为：已报价
              + 被分配
              + 当前用户为此需求的服务集成商（判断id）
           -->
          <cooperation-type
            v-if="showCooperationType"
            :detail="detail"
            @reload="getRequireFormDetail">
          </cooperation-type>

          <!-- 子需求列表 -->
          <sub-require
            v-if="showSubRequire"
            :detail="detail">
          </sub-require>

          <!-- 合同信息 -->
          <contract-info
            v-if="showContractInfo"
            :detail="detail"
            :isPublisher="isPublisher"
            :isPartyB="isPartyB"
            @reload="getRequireFormDetail">
          </contract-info>

          <!--
            需求状态 需求状态一直存在
              + 下面需显示服务提供商的类型
              + 机构 用户名
           -->
          <div class="info_module">
            <h1>需求状态</h1>
            <div class="module_main">
              <info label-width="140px">
                <info-item
                  label="需求状态">
                  {{ detail.logicStatus | findLabel(globalVar.REQUIRE_STATUS_OPTIONS) }}
                </info-item>
                <info-item
                  v-if="showServiceOrg"
                  label="服务提供方">
                  <template v-if="this.isDistributorReq">
                    {{ detail.distributorOrgName }}  {{ detail.distributorName }}
                  </template>
                  <template v-else-if="this.isReceiverReq">
                    {{ detail.receiverOrgName }}  {{ detail.receiverName }}
                  </template>
                </info-item>
              </info>
            </div>
          </div>

          <!--
            接受拒绝
              + 被分配
                + 角色为需求发布方(只有分配给服务集成商，需求提供方才会有接受或拒绝按钮)
                  + 状态为已接受: 接受代理、拒绝代理
                + 角色为服务提供商
                  + 状态为待处理: 接受需求、拒绝需求
           -->
          <div v-if="showAllocateBtns" class="tw-flex tw-mt-20px">
            <el-button type="primary" size="small" @click="handelAllocate(1)">接受</el-button>
            <el-button type="danger" size="small" @click="handelAllocate(0)">拒绝</el-button>
          </div>

          <div v-if="showReportBtns" class="tw-flex tw-mt-20px">
            <el-button type="primary" size="small" @click="handleReport(1)">接受代理</el-button>
            <el-button type="danger" size="small" @click="handleReport(0)">拒绝代理</el-button>
          </div>
        </template>
      </div>
    </div>

    <!-- 客服备注 问题信息 -->
    <question-info
      v-if="detail.reportFlag"
      :visible.sync="questionInfoDialogVisible"
      :questionList="detail.questions">
    </question-info>
  </div>
</template>

<script>

import * as api from '@/api'
import DiagnosticReport from './components/diagnostic-report.vue'
import DiscussPrice from './components/discuss-price/index.vue'
import cooperationType from './components/cooperation-type'
import SubRequire from './components/sub-require/index.vue'
import ContractInfo from './components/contract-info/index.vue'
import questionInfo from './components/question-info.vue'
import globalVar from '@/configs/globalVar'
import InfoItem from '@/components/info/info-item.vue'

export default {
  components: {
    DiagnosticReport,
    DiscussPrice,
    cooperationType,
    SubRequire,
    ContractInfo,
    questionInfo,
    InfoItem
  },
  data () {
    return {
      // 页面loading
      loading: false,
      // 客服备注的问题弹窗
      questionInfoDialogVisible: false,
      // 详情
      detail: {
        title: '', // 需求名称
        serviceTypeId: '', // 需求类别
        serviceTypeName: '',
        industryId: '', // 行业领域
        industryName: '',
        technicalId: '', // 技术领域
        technicalName: '', //
        description: '', // 需求描述
        keywords: '', // 关键词
        budget: '', // 预算金额
        enterpriseName: '', // 需求所属企业名称
        socialCreditCode: '', // 统一社会信用代码
        province: '', // 企业所在区域
        contacts: '', // 联系人
        contactMobile: '', // 联系手机
        contactEmail: '', // 联系邮箱
        startAt: '', // 时限要求
        endAt: '', // 时限要求
        logicStatus: -1,
        quotationList: []
      }
    }
  },
  computed: {
    // 需求id
    requireId () {
      return this.$route.params.id || ''
    },
    // 需求状态
    reqStatus () {
      return this.detail.logicStatus
    },
    // 当前用户id
    curUserId () {
      return this.userInfo.id
    },
    // 技术领域
    technicalList () {
      return this.$store.state.technicalList
    },
    // 行业领域下拉列表
    industrySectorList () {
      return this.$store.state.industrySectorList
    },
    // 一级服务类型
    serviceTypeLevel1 () {
      return this.$store.state.serviceTypeLevel1
    },
    // 是否是被分配的需求: 被分配者id存在
    isDistributorReq () {
      return this.detail.distributor
    },
    // 是否是被正常接受的需求: 接受者id存在
    isReceiverReq () {
      return this.detail.receiver
    },
    // 当前账号是否是需求发布者
    isPublisher () {
      return this.detail.publisher === this.curUserId
    },
    // 当前账号是否是被分配者
    isDistributor () {
      return this.detail.distributor === this.curUserId
    },
    // 当前账号是否是接受者
    isReceiver () {
      return this.detail.receiver === this.curUserId
    },
    // 是否是乙方
    isPartyB () {
      return this.isDistributor || this.isReceiver
    },
    // 此需求是否被分配给集成商用户
    isDistributorAggregator () {
      return this.isDistributorReq && this.detail.distributorRole === globalVar.USER_ROLE_2
    },
    // 能否显示其他模块信息：状态!== 已提交或已取消
    showModules () {
      /**
       * 除已提交、已取消两种状态
       */
      return [
        globalVar.REQUIRE_STATUS_SUBMITTED,
        globalVar.REQUIRE_STATUS_CANCELLED
      ].indexOf(this.reqStatus) === -1
    },
    /**
     * 是否显示诊断报告
     *  + 为被分配的需求
     *  + 为复杂需求 reportFlag===1
     *  + 状态为已接受或者已确认
     */
    showDiagnosticReport () {
      return this.isDistributorReq && this.detail.reportFlag && [
        globalVar.REQUIRE_STATUS_RECEIVED,
        globalVar.REQUIRE_STATUS_CONFIRMED
      ].indexOf(this.reqStatus) !== -1
    },
    /**
     * 是否显示价格磋商模块
     *  + 磋商列表大于0的话双方都显示（正常接受的需求在接受需求的时候就已经提出报价了）
        + 否则 当前为被分配者 复杂需求的话状态为已确认 简单需求的话状态为已接受
     */
    showDiscussPrice () {
      return this.detail.quotationList.length > 0 ||
        (
          this.isDistributor && (
            this.detail.reportFlag ? this.reqStatus === globalVar.REQUIRE_STATUS_CONFIRMED : this.reqStatus === globalVar.REQUIRE_STATUS_RECEIVED
          )
        )
    },
    /**
     * 是否显示合作方式
     *  + 为复杂需求
     *  + 自己为被分配者
     *  + 分配的服务集成商
     *  + 已确认报价
     */
    showCooperationType () {
      return this.detail.reportFlag &&
        this.isDistributor &&
        this.isDistributorAggregator &&
        this.reqStatus === globalVar.REQUIRE_STATUS_QUOTED && !this.detail.cooperationMode
    },
    /**
     * 是否显示合同信息
     *  + 如果是复杂需求并且分配给了服务集成商则必须先选择合作方式
     *  + 否则状态必须为已报价
     */
    showContractInfo () {
      return this.detail.reportFlag && this.isDistributorAggregator ? this.detail.cooperationMode : (
        (this.isDistributorReq || this.isReceiverReq) && [
          globalVar.REQUIRE_STATUS_QUOTED,
          globalVar.REQUIRE_STATUS_ACTING,
          globalVar.REQUIRE_STATUS_DOCKING,
          globalVar.REQUIRE_STATUS_COMPLETED
        ].indexOf(this.reqStatus) !== -1
      )
    },
    /**
     * 是否显示子需求
     *  + 选择了合作方式
     *  + 如果合作方式为1，则双方都可见，否则只有被分配方可见
     */
    showSubRequire () {
      return this.detail.cooperationMode === 1 || (this.detail.cooperationMode === 2 && this.isDistributor)
    },
    /**
     * 是否显示服务提供商或服务集成商接受需求，拒绝需求的按钮
     *  + 自己是被分配者
     *  + 状态为待处理
     */
    showAllocateBtns () {
      return this.isDistributor && this.reqStatus === globalVar.REQUIRE_STATUS_PENDING
    },
    /**
     * 是否显示需求发布方接受代理，拒绝代理的按钮
     *  + 是被分配的需求
     *  + 且为发布者
     *  + 且存在诊断报告
     *  + 状态为已接受
     */
    showReportBtns () {
      return this.isDistributorReq && this.isPublisher && this.detail.diagnosticReport && this.reqStatus === globalVar.REQUIRE_STATUS_RECEIVED
    },
    /**
     * 显示服务提供商的信息
     *  + 是否是被分配的需求
     *    + 非已提交或已取消
     *  + 是否是被接受的需求
     *    + 非已提交或已取消或已发布
     */
    showServiceOrg () {
      return this.isDistributorReq ? (
        [
          globalVar.REQUIRE_STATUS_SUBMITTED,
          globalVar.REQUIRE_STATUS_CANCELLED
        ].indexOf(this.reqStatus) === -1
      ) : this.isReceiverReq ? (
        [
          globalVar.REQUIRE_STATUS_SUBMITTED,
          globalVar.REQUIRE_STATUS_CANCELLED,
          globalVar.REQUIRE_STATUS_PUBLISHED
        ].indexOf(this.reqStatus) === -1
      ) : false
    }
  },
  created () {
    // 获取需求详情
    this.getRequireFormDetail()
  },
  methods: {
    // 获取需求详情
    getRequireFormDetail () {
      this.loading = true
      api.getRequireFormDetail(this.requireId).then(res => {
        if (res.data.code === 0) {
          console.log('需求详情', res.data.data)
          this.detail = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    // 服务方 接受或拒绝分配
    handelAllocate (Action) {
      this.$msgbox({
        title: '确认',
        message: '请确认你的操作',
        showCancelButton: true,
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            api.handleAllocate(this.requireId, {
              action: Action
            }).then(res => {
              if (res.data.code === 0) {
                this.$message.success('操作成功')
                if (Action === 0) {
                  this.$router.replace('/require-manage')
                } else if (Action === 1) {
                  this.getRequireFormDetail()
                }
              } else {
                this.$message.error(res.data.message)
              }
            }).catch(err => {
              console.log(err)
            }).finally(() => {
              instance.confirmButtonLoading = false
              done()
            })
          } else {
            done()
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 需求方 接受或拒绝代理
    handleReport (Action) {
      this.$msgbox({
        title: '确认',
        message: '请确认你的操作',
        showCancelButton: true,
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            api.handleReport(this.requireId, {
              action: Action
            }).then(res => {
              if (res.data.code === 0) {
                this.$message.success('操作成功')
                this.getRequireFormDetail()
              } else {
                this.$message.error(res.data.message)
              }
            }).catch(err => {
              console.log(err)
            }).finally(() => {
              instance.confirmButtonLoading = false
              done()
            })
          } else {
            done()
          }
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss">
.detail {
  .detail_main {
    padding: 30px 0;
    .main_content {
      width: 820px;
      margin: 0 auto;
      .info_module {
        padding: 10px 30px;
        border: 1px solid #DCDFE6;
        border-radius: 6px;
        & > h1 {
          font-size: 16px;
          font-weight: bold;
          line-height: 60px;
          color: #303133;
        }
        .module_main {
          padding: 0 10px;
        }
        &+.info_module {
          margin-top: 10px;
        }
      }
      .base_info {

      }
    }
  }
}
</style>
