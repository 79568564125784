<!-- 子需求详情 -->
<template>
  <el-dialog
    custom-class="my-el-dialog"
    title="子需求详情"
    :visible.sync="$_visible"
    width="640px"
    :close-on-click-modal="false"
    append-to-body
    @open="onDialogOpen">
    <div class="tw-el-dialog-max-height tw--mx-20px tw-px-40px">
      <info label-width="140px">
        <info-item
          label="需求名称">
          {{ detail.title }}
        </info-item>
        <info-item
          label="需求类别">
          {{ detail.serviceTypeName }}
        </info-item>
        <info-item
          label="行业领域">
          {{ detail.industryName ? detail.industryName.join(',') : '' }}
        </info-item>
        <info-item
          label="技术领域">
          {{ detail.technicalName ? detail.technicalName.join(',') : '' }}
        </info-item>
        <info-item
          label="时限要求">
          {{ detail.startAt }} - {{ detail.endAt }}
        </info-item>
        <info-item
          label="需求描述">
          {{ detail.description }}
        </info-item>
        <info-item
          label="关键字">
          {{ detail.keywords }}
        </info-item>
        <info-item
          label="预算金额">
          {{ detail.budget }}元
        </info-item>
        <info-item
          label="需求所属企业名称">
          {{ detail.enterpriseName }}
        </info-item>
        <info-item
          label="统一社会信用代码">
          {{ detail.socialCreditCode }}
        </info-item>
        <info-item
          label="企业所在区域">
          {{ detail.province }}
        </info-item>
        <info-item
          label="联系人">
          {{ detail.contacts }}
        </info-item>
        <info-item
          label="联系手机">
          {{ detail.contactMobile }}
        </info-item>
        <info-item
          label="联系邮箱">
          {{ detail.contactEmail }}
        </info-item>
      </info>
    </div>
    <div slot="footer" class="footer">
      <el-button
        size="small"
        @click="closeDialog">
        关闭
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as api from '@/api'

export default {
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    },
    requireId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      // 子需求详情
      detail: {}
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    }
  },
  created () {
  },
  methods: {
    closeDialog () {
      this.$_visible = false
    },
    onDialogOpen () {
      api.getRequireDetail(this.requireId).then(res => {
        if (res.data.code === 0) {
          this.detail = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .item_icon_box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #DCDFE6;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 42px;
    &:hover {
      background: #fff;
      border-color: #3473E6;
      color: #3473E6;
    }
  }
</style>
