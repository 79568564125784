<!-- 诊断报告 只会存在服务集成商接受了需求，上传了诊断报告，让需求发布方来下载，接受或拒绝代理才会存在 -->
<template>
  <div class="info_module">
    <h1>诊断报告</h1>
    <div class="module_main">
      <info label-width="140px">
        <info-item
          label="报告文件">
          <template v-if="isPublisher">
            <el-button
              v-if="detail.diagnosticReport"
              type="primary"
              plain
              size="small"
              @click="onDownload">
              点击下载
            </el-button>
            <template v-else>暂无文件</template>
          </template>
          <template v-else>
            <files-list
              v-if="detail.diagnosticReport"
              :list="[detail.diagnosticReport]">
            </files-list>
            <upload-files
              v-else
              accept=""
              :showTip="false"
              :readOnly="true"
              @successUpload="onUploadSuccess">
            </upload-files>
          </template>
        </info-item>
      </info>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import FilesList from '@/components/upload-file/files-list'

export default {
  components: {
    FilesList
  },
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    isPublisher () {
      return this.detail.publisher === this.userInfo.id
    }
  },
  methods: {
    onDownload () {
      window.open(this.detail.diagnosticReport.downloadUrl)
    },
    onUploadSuccess (file, res) {
      api.uploadReport(this.detail.id, {
        mediaId: res.data.id
      }).then(res => {
        if (res.data.code === 0) {
          this.$emit('reload')
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
