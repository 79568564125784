<!-- 合作方式 -->
<template>
  <div class="info_module">
    <h1>合作方式</h1>
    <div class="module_main">
      <div class="tw-flex tw-justify-between tw-pt-10px tw-pb-30px">
        <div class="cooperation_item type1" @click="onConfirm(1)">
          <h1>多方协作</h1>
          <div class="item_main">
            多方协作，全程透明。需求发布方可以看到子需求以及子需求报价等信息，并且签署的是多方合作协议
          </div>
        </div>
        <div class="cooperation_item type2" @click="onConfirm(2)">
          <h1>需求总包</h1>
          <div class="item_main">
            需求发布方只需要与服务集成商对接，无法知道需求的具体执行细节，全程由服务集成商负责。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'

export default {
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
    }
  },
  methods: {
    onConfirm (type) {
      this.$msgbox({
        title: '确认',
        message: '请确认你的操作',
        showCancelButton: true,
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            api.updateRequire(this.detail.id, {
              cooperationMode: type
            }).then(res => {
              if (res.data.code === 0) {
                this.$message.success('操作成功')
                this.$emit('reload')
              } else {
                this.$message.error(res.data.message)
              }
            }).catch(err => {
              console.log(err)
            }).finally(() => {
              instance.confirmButtonLoading = false
              done()
            })
          } else {
            done()
          }
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .cooperation_item {
    width: 360px;
    background: #FFFFFF;
    border: 1px solid #DCDFE6;
    border-radius: 6px;
    padding: 16px 20px 20px 20px;
    transition: all 0.3s ease;
    background-position: right 90%;
    background-size: 60px 60px;
    background-repeat: no-repeat;
    cursor: pointer;
    & > h1 {
      font-size: 18px;
      font-weight: bold;
      line-height: 40px;
      color: #303133;
      &:hover {
        color: #3473E6;
      }
    }
    &:hover {
      border: 1px solid #3473E6;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
      & > h1 {
        color: #3473E6;
      }
    }
    &.type1 {
      background-image: url('../../../../assets/images/require-detail/type1.png');
    }
    &.type2 {
      background-image: url('../../../../assets/images/require-detail/type2.png');
    }
    .item_main {
      padding: 10px 80px 0 0;
    }
  }
</style>
