<template>
  <el-dialog
    custom-class="my-el-dialog"
    title="客服备注"
    :visible.sync="$_visible"
    width="600px"
    :close-on-click-modal="false"
    append-to-body>
    <div class="dialog-max-height tw--mx-20px tw-px-20px">
      <el-row
        class="row_item"
        v-for="item in questionList"
        :key="item.prop"
        type="flex">
        <div class="text_color_info" style="width:60px;">
          问题{{ item.prop }}:
        </div>
        <div style="width:460px;">
          <p class="text_color_info tw-mb-15px">{{ item.label }}</p>
          <p>{{ item.value }}</p>
        </div>
      </el-row>
    </div>
    <div slot="footer" class="footer">
      <el-button
        size="small"
        @click="closeDialog">
        关闭
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    // 问题列表
    questionList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    }
  },
  methods: {
    closeDialog () {
      this.$_visible = false
    }
  }
}
</script>
<style lang="scss" scoped>
  .dialog-max-height {
    max-height: 500px;
    overflow: auto;
  }
  .row_item {
    margin-bottom: 22px;
  }
</style>
