<template>
  <div class="step_box">
    <h1>填写合同信息</h1>
    <el-form
      class="tw-px-10px"
      ref="form"
      :model="form"
      :rules="rules"
      label-width="120px"
      label-position="left"
      size="small">
      <el-form-item
        prop="reqName"
        label="项目名称">
        <el-input maxlength="50" show-word-limit
          readonly
          :value="requireTitle">
        </el-input>
      </el-form-item>
      <el-form-item
        prop="u1Name"
        label="委托方（甲方）">
        <el-input maxlength="50" show-word-limit
          v-model="form.u1Name">
        </el-input>
      </el-form-item>
      <el-form-item
        prop="u2Name"
        label="受托方（乙方）">
        <el-input maxlength="50" show-word-limit
          v-model="form.u2Name">
        </el-input>
      </el-form-item>
      <el-form-item
        prop="var1"
        label="服务目标">
        <el-input
          style="width: 100%;"
          type="textarea" maxlength="300" show-word-limit
          :rows="4"
          placeholder="请输入服务目标"
          v-model="form.var1">
        </el-input>
      </el-form-item>
      <el-form-item
        prop="var2"
        label="服务内容">
        <el-input
          style="width: 100%;"
          type="textarea" maxlength="300" show-word-limit
          :rows="4"
          placeholder="请输入服务内容"
          v-model="form.var2">
        </el-input>
      </el-form-item>
      <el-form-item
        prop="var3"
        label="服务方式">
        <el-input
          style="width: 100%;"
          type="textarea" maxlength="300" show-word-limit
          :rows="4"
          placeholder="请输入服务方式"
          v-model="form.var3">
        </el-input>
      </el-form-item>
      <el-form-item
        prop="fwqx"
        label="服务期限">
        <el-date-picker
          type="daterange"
          v-model="form.fwqx"
          format="yyyy-MM-dd"
          style="width: 100%"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          range-separator="~"
          clearable>
        </el-date-picker>
      </el-form-item>
      <el-form-item
        prop="var10"
        label="总金额（大写）">
        <el-input maxlength="50" show-word-limit
          v-model="form.var10">
        </el-input>
      </el-form-item>
      <el-form-item
        prop="var11"
        label="总金额（小写）">
        <el-input maxlength="50" show-word-limit
          v-model="form.var11">
        </el-input>
      </el-form-item>
      <el-form-item
        prop="var12"
        label="技术成果归属方">
        <el-select
          v-model="form.var12"
          placeholder="甲方利用乙方提交的服务工作成果所完成的新的技术成果"
          title="甲方利用乙方提交的服务工作成果所完成的新的技术成果"
          clearable
          style="width: 100%">
          <el-option
            value="甲">
            甲
          </el-option>
          <el-option
            value="双">
            双
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        prop="var13"
        label="技术成果归属方">
        <el-select
          v-model="form.var13"
          placeholder="乙方利用甲方提供的技术资料和工作条件所完成的新的技术成果"
          title="乙方利用甲方提供的技术资料和工作条件所完成的新的技术成果"
          clearable
          style="width: 100%">
          <el-option
            value="乙">
            乙
          </el-option>
          <el-option
            value="双">
            双
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          plain
          size="small"
          :loading="btnLoading"
          @click="submitForm">
          下一步
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import * as api from '@/api'
export default {
  data () {
    return {
      // 按钮loading
      btnLoading: false,
      // 合同信息表单
      form: {
        reqName: '', // 项目名称（需求标题）
        u1Name: '', // 委托方（甲方）
        u2Name: '', // 乙方名称
        var1: '', // 服务目标
        var2: '', // 服务内容
        var3: '', // 服务方式
        fwqx: '',
        var10: '',
        var11: '',
        var12: '',
        var13: ''
      },
      // form: {
      //   reqName: '',
      //   u1Name: '',
      //   u2Name: '',
      //   var1: '进入500强进入500强进入500强进入500强进入500强进入500强进入500强进入500强进入',
      //   var2: '造钱造钱造钱造钱造钱造钱造钱造钱造钱造钱造钱造钱造钱造钱造钱造钱造钱造钱造钱造钱造钱造钱',
      //   var3: '打钱打钱打钱打钱打钱打钱打钱打钱打钱打钱打钱打钱打钱打钱打钱',
      //   fwqx: [],
      //   var10: '壹拾贰万',
      //   var11: '120000',
      //   var12: '双',
      //   var13: '双'
      // },
      rules: {
        title: [
          { required: true, message: '请输入项目名称', trigger: 'blur' }
        ],
        u1Name: [
          { required: true, message: '请输入委托方（甲方）', trigger: 'blur' }
        ],
        u2Name: [
          { required: true, message: '请输入委托方（乙方）', trigger: 'blur' }
        ],
        var1: [
          { required: true, message: '请输入服务目标', trigger: 'blur' }
        ],
        var2: [
          { required: true, message: '请输入服务内容', trigger: 'blur' }
        ],
        var3: [
          { required: true, message: '请输入服务方式', trigger: 'blur' }
        ],
        fwqx: [
          { required: true, message: '请选择服务期限', trigger: 'blur' }
        ],
        startAt: [
          { required: true, message: '请输入服务期限', trigger: 'blur' }
        ],
        var10: [
          { required: true, message: '请输入总金额（大写）', trigger: 'blur' }
        ],
        var11: [
          { required: true, message: '请输入总金额（小写）', trigger: 'blur' }
        ],
        var12: [
          { required: true, message: '请选择技术成果归属方', trigger: 'change' }
        ],
        var13: [
          { required: true, message: '请选择技术成果归属方', trigger: 'change' }
        ]
      }
    }
  },
  props: {
    // 需求详情
    detail: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    // 需求id
    requireId () {
      return this.detail.id || ''
    },
    // 需求标题
    requireTitle () {
      return this.detail.title || ''
    }
  },
  methods: {
    // 提交表单
    submitForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 取出服务期限
          const { fwqx } = this.form
          const [start, end] = fwqx
          this.form.var4 = start.getFullYear()
          this.form.var5 = this.padDate(start.getMonth() + 1)
          this.form.var6 = this.padDate(start.getDate())
          this.form.var7 = this.padDate(end.getFullYear())
          this.form.var8 = this.padDate(end.getMonth() + 1)
          this.form.var9 = this.padDate(end.getDate())
          // 赋值需求标题
          this.form.reqName = this.requireTitle
          console.log(this.form)
          // 提交合同信息
          this.submitContractInfo()
          // 下一步
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 提交合同信息
    submitContractInfo () {
      this.btnLoading = true
      api.submitContractInfo(this.requireId, {
        ...this.form,
        fwqx: ''
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('表单信息提交成功')
          this.$emit('reload')
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.btnLoading = false
      })
    },
    // 给时间补零
    padDate (value) {
      return value < 10 ? '0' + value : value
    }
  }
}
</script>

<style lang="scss" scoped>
  .step_box {
    width: 470px;
    & > h1 {
      font-size: 14px;
      line-height: 40px;
      color: #303133;
      margin-bottom: 10px;
    }
  }
</style>
