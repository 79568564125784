<!-- 新增子需求 -->
<template>
  <el-dialog
    custom-class="my-el-dialog"
    title="新增子需求"
    :visible.sync="$_visible"
    width="640px"
    :close-on-click-modal="false"
    append-to-body
    @close="onDialogClose">
    <div class="tw-el-dialog-max-height tw--mx-20px tw-px-40px">
      <create-form
        ref="createForm">
      </create-form>
    </div>
    <div slot="footer" class="footer">
      <el-button
        size="small"
        @click="closeDialog">
        取消
      </el-button>
      <el-button
        type="primary"
        size="small"
        :loading="saveBtnLoading"
        @click="getRequireForm">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import createForm from '../../../components/require-create/create-form'
import * as api from '@/api'

export default {
  components: {
    createForm
  },
  data () {
    return {
      saveBtnLoading: false
    }
  },
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    },
    parentId: {
      type: String,
      default: ''
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    }
  },
  created () {
  },
  methods: {
    // 获取需求表单
    async getRequireForm () {
      let data = {}
      try {
        data = await this.$refs.createForm.validate()
        this.saveBtnLoading = true
        api.createRequirement({
          ...data,
          parentId: this.parentId
        }).then(res => {
          if (res.data.code === 0) {
            this.$message.success('发布子需求成功')
            this.$emit('onCreate')
            this.closeDialog()
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          console.log(err)
          this.$message.error('请求出错')
        }).finally(() => {
          this.saveBtnLoading = false
        })
      } catch (err) {
        console.log(err)
      }
    },
    // 关闭新建里程碑的dialog
    closeDialog () {
      this.$_visible = false
    },
    // dialog关闭事件
    onDialogClose () {
      this.$refs.createForm.resetForm()
    }
  }
}
</script>
<style lang="scss" scoped>
  .item_icon_box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #DCDFE6;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 42px;
    &:hover {
      background: #fff;
      border-color: #3473E6;
      color: #3473E6;
    }
  }
</style>
