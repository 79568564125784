<!-- 子需求 -->
<template>
  <div class="info_module">
    <div class="module_header">
      <h1>子需求</h1>
      <el-button
        v-if="isDistributor"
        size="small"
        @click="addDialogVisible = true">
        增加子需求
      </el-button>
    </div>
    <div class="module_main">
      <div class="tw-flex tw-flex-wrap tw-justify-between tw-pt-10px tw-pb-30px">
        <div
          class="sub_item"
          v-for="item in requireList"
          :key="item.id"
          @click="getItemInfo(item)">
          <div class="item_content">
            <div class="item_left">
              <h1>{{ item.title }}</h1>
              <p>{{ item.description }}</p>
              <div
                v-if="showFooterBtn(item)"
                class="left_footer">
                <el-button
                  size="small"
                  type="primary"
                  plain
                  @click.stop="onSelectUser(item)">
                  选择服务用户
                </el-button>
                <el-button
                  size="small"
                  type="primary"
                  plain
                  @click.stop="onReleaseItem(item)">
                  发布
                </el-button>
              </div>
            </div>
            <div class="item_right">
              <p
                v-if="showRevokeBtn(item)"
                class="delete_text_btn"
                @click.stop="onRevokeItem(item)">
                撤销
              </p>
            </div>
          </div>
          <div class="item_footer" v-if="showFooterText(item)">
            <p class="tw-text-primary-text tw-text-14px">
              {{item.distributorOrgName}} {{item.distributorName}}
              <span class="tw-ml-10px">
                {{ item.logicStatus | findLabel(globalVar.REQUIRE_STATUS_OPTIONS) }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- 新增子需求 -->
    <add-sub-require
      :visible.sync="addDialogVisible"
      :parent-id="detail.id"
      @onCreate="getRequireList">
    </add-sub-require>

    <!-- 子需求信息 -->
    <sub-require-info
      :visible.sync="infoDialogVisible"
      :require-id="selectedId">
    </sub-require-info>

    <!-- 选择服务用户 -->
    <select-user
      :visible.sync="userDialogVisible"
      :require-id="selectedId"
      @allocate-success="getRequireList">
    </select-user>
  </div>
</template>

<script>
import AddSubRequire from './add-sub-require.vue'
import SubRequireInfo from './sub-require-info.vue'
import SelectUser from './select-user'
import * as api from '@/api'
import globalVar from '@/configs/globalVar'

export default {
  components: {
    AddSubRequire,
    SubRequireInfo,
    SelectUser
  },
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      // 新增子需求
      addDialogVisible: false,
      // 子需求信息
      infoDialogVisible: false,
      // 为子需求选择服务用户弹窗
      userDialogVisible: false,
      // 子需求列表
      requireList: [],
      selectedId: ''
    }
  },
  computed: {
    isDistributor () {
      return this.detail.distributor === this.userInfo.id
    },
    showFooterBtn () {
      return (item) => {
        return this.isDistributor && item.logicStatus === globalVar.REQUIRE_STATUS_SUBMITTED
      }
    },
    showFooterText () {
      return (item) => {
        return [
          globalVar.REQUIRE_STATUS_SUBMITTED
        ].indexOf(item.logicStatus) === -1
      }
    },
    showRevokeBtn () {
      return (item) => {
        return this.isDistributor && item.logicStatus === globalVar.REQUIRE_STATUS_SUBMITTED
      }
    }
  },
  mounted () {
    this.getRequireList()
  },
  methods: {
    getRequireList () {
      api.getSubRequireList(this.detail.id, {
        pageSize: 100,
        pageNum: 1
      }).then(res => {
        if (res.data.code === 0) {
          this.requireList = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getItemInfo (item) {
      this.infoDialogVisible = true
      this.selectedId = item.id
    },
    onSelectUser (item) {
      this.userDialogVisible = true
      this.selectedId = item.id
    },
    /**
     * 直接发布
     */
    onReleaseItem (item) {
      this.$msgbox({
        title: '确认',
        message: '确定要发布子需求吗？',
        showCancelButton: true,
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            api.updateRequireStatus(item.id, {
              status: globalVar.REQUIRE_STATUS_PUBLISHED
            }).then(res => {
              if (res.data.code === 0) {
                this.$message.success('操作成功')
                this.getRequireList()
              } else {
                this.$message.error(res.data.message)
              }
            }).catch(err => {
              console.log(err)
            }).finally(() => {
              instance.confirmButtonLoading = false
              done()
            })
          } else {
            done()
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 删除子需求 仅限于本地删除
    onRevokeItem (item) {
      this.$msgbox({
        title: '确认',
        message: '确定要撤销此需求吗？',
        showCancelButton: true,
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            api.revokeMyRequire(item.id).then(res => {
              if (res.data.code === 0) {
                this.$message.success('操作成功')
                this.getRequireList()
              } else {
                this.$message.error(res.data.message)
              }
            }).catch(err => {
              console.log(err)
            }).finally(() => {
              instance.confirmButtonLoading = false
              done()
            })
          } else {
            done()
          }
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .info_module {
    .module_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      & > h1 {
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
        color: #303133;
      }
    }
    .sub_item {
      width: 360px;
      background: #FFFFFF;
      border: 1px solid #DCDFE6;
      border-radius: 6px;
      padding: 16px 20px 20px 20px;
      transition: all 0.3s ease;
      cursor: pointer;
      margin-bottom: 20px;
      .item_content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .item_left {
          flex: 0 0 240px;
          & > h1 {
            font-size: 18px;
            font-weight: bold;
            line-height: 40px;
            color: #303133;
            &:hover {
              color: #3473E6;
            }
          }
          & > p {
            min-height: 60px;
            line-height: 20px;
            font-size: 14px;
            color: #909399;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            margin: 10px 0 50px 0;
          }
        }
        .item_right {
          .delete_text_btn {
            font-size: 14px;
            line-height: 20px;
            color: #F36A6E;
            cursor: pointer;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
      .item_footer {
        display: flex;
        align-items: center;
        height: 32px;
        .left_footer {
        }
      }
      &:hover {
        border: 1px solid #3473E6;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
        & > h1 {
          color: #3c424d;
        }
      }
    }
  }
</style>
